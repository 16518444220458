<template>
  <div class="bubbles">
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
  </div>
</template>

<style scoped>
.bubbles {
  /* min-height: 106vh; */
  position: absolute;
  z-index: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubble {
  position: absolute;
  background: #56c292;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0;
  animation: slide-in1 5s ease-out infinite;
  overflow: hidden;
}

@keyframes slide-in1 {
  0% {
    transform: translate(-100vw, -100vh);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: translate(50%, 50%);
    opacity: 0;
  }
}

.bubble:nth-child(2) {
  width: 20px;
  height: 20px;
  animation: slide-in2 8s ease-out infinite;
  /* animation-delay: 1s; */
}

@keyframes slide-in2 {
  0% {
    transform: translate(0vw, -100vh);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: translate(50%, 50%);
    opacity: 0;
  }
}

.bubble:nth-child(3) {
  width: 50px;
  height: 50px;
  animation: slide-in3 7s ease-out infinite;
  animation-delay: -1s;
}

@keyframes slide-in3 {
  0% {
    transform: translate(90vw, -100vh);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: translate(50%, 50%);
    opacity: 0;
  }
}

.bubble:nth-child(4) {
  width: 80px;
  height: 80px;
  animation: slide-in4 11s ease-out infinite;
  /* animation-delay: 0s; */
}

@keyframes slide-in4 {
  0% {
    transform: translate(-100vw, -30vh);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: translate(50%, 50%);
    opacity: 0;
  }
}

.bubble:nth-child(5) {
  width: 35px;
  height: 35px;
  animation: slide-in5 6s ease-out infinite;
  /* animation-delay: 1s; */
}

@keyframes slide-in5 {
  0% {
    transform: translate(-100vw, 0vh);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: translate(50%, 50%);
    opacity: 0;
  }
}

.bubble:nth-child(6) {
  width: 45px;
  height: 45px;
  animation: slide-in6 8s ease-out infinite;
  animation-delay: -2s;
}

@keyframes slide-in6 {
  0% {
    transform: translate(-100vw, 50vh);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: translate(50%, 50%);
    opacity: 0;
  }
}

.bubble:nth-child(7) {
  width: 90px;
  height: 90px;
  animation: slide-in7 12s ease-out infinite;
  animation-delay: -1s;
}

@keyframes slide-in7 {
  0% {
    transform: translate(100vw, -40vh);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: translate(50%, 50%);
    opacity: 0;
  }
}

.bubble:nth-child(8) {
  width: 25px;
  height: 25px;
  animation: slide-in8 6s ease-out infinite;
  animation-delay: -1s;
}

@keyframes slide-in8 {
  0% {
    transform: translate(100vw, 30vh);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: translate(50%, 50%);
    opacity: 0;
  }
}

.bubble:nth-child(9) {
  width: 15px;
  height: 15px;
  animation: slide-in9 5s ease-out infinite;
  /* animation-delay: 1s; */
}

@keyframes slide-in9 {
  0% {
    transform: translate(-50vw, 100vh);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: translate(50%, 50%);
    opacity: 0;
  }
}

.bubble:nth-child(10) {
  width: 90px;
  height: 90px;
  animation: slide-in10 10s ease-out infinite;
  animation-delay: -3s;
}

@keyframes slide-in10 {
  0% {
    transform: translate(30vw, 100vh);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: translate(50%, 50%);
    opacity: 0;
  }
}
</style>
